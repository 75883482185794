import { useState } from 'react';
import { Comment, Avatar, Tooltip, Typography, Button, Popover, Tag } from 'antd';
import moment from 'moment';
import { SmileOutlined } from '@ant-design/icons';
import { ChatMessage } from '../../entities/chat';
import { UserBrief } from '../../../dal';
import UserHoverCard from './UserHoverCard';
import MessageMenuPopup from './MessageMenuPopup';
import { useAppDispatch } from '../../../hooks/redux';
import { updateMessageReaction } from '../../../store/reducers/messagesSlice';
import EmojiPicker from 'emoji-picker-react';
import { CHAT_UPLOAD_BASE_URL, DEFAULT_AVATAR } from '../../constants';

const { Text } = Typography;

interface MessageProps {
  message: ChatMessage;
  channelId: string;
  loggedInUserId: number;
  users: UserBrief[];
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>;
}

const Message = ({ message, channelId, loggedInUserId, users, setUser }: MessageProps) => {
  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const [showMessageReactionMenuPopup, setShowMessageReactionMenuPopup] = useState(false);

  const dispatch = useAppDispatch();

  const handleReaction = (emojiData: { emoji: string } | string) => {
    const emoji = typeof emojiData === 'string' ? emojiData : emojiData.emoji;

    const request = {
      messageId: message.id,
      channelId,
      emoji,
    };

    dispatch(updateMessageReaction(request));
    setShowMessageReactionMenuPopup(false);
  };
  const reactionsMap = message.reactions.reduce<Record<string, { count: number; users: string[] }>>((acc, reaction) => {
    const userName = users.find(u => u.id === reaction.userId)?.name || 'Unknown';

    if (!acc[reaction.emoji]) {
      acc[reaction.emoji] = { count: 0, users: [] };
    }
    acc[reaction.emoji].count++;
    acc[reaction.emoji].users.push(userName);
    return acc;
  }, {});

  const reactionButtons = Object.entries(reactionsMap).map(([emoji, { count, users }]) => {
    const isReactedByMe = message.reactions.some(r => r.userId === loggedInUserId && r.emoji === emoji);

    return (
      <Tooltip key={emoji} title={users.join(', ')}>
        <Tag color={isReactedByMe ? 'processing' : 'default'} onClick={() => handleReaction(emoji)} style={{ cursor: 'pointer' }}>
          {emoji} {count}
        </Tag>
      </Tooltip>
    );
  });

  const actions = message.reactions.length > 0 && (
    <div>
      {reactionButtons}

      <Popover
        content={<EmojiPicker onEmojiClick={handleReaction} />}
        trigger="click"
        open={showMessageReactionMenuPopup}
        onOpenChange={setShowMessageReactionMenuPopup}
      >
        <Button shape="circle" size="small" icon={<SmileOutlined />} />
      </Popover>
    </div>
  );

  return (
    <div
      className="chat-message"
      onMouseEnter={() => setShowMenuPopup(true)}
      onMouseLeave={() => setShowMenuPopup(false)}
      style={{ backgroundColor: showMenuPopup ? 'rgba(0, 0, 0, 0.01)' : 'transparent' }}
    >
      <Comment
        actions={[actions]}
        author={<Text strong>{getNameFromEmail(message?.author?.email || '') || 'Sender name'}</Text>}
        avatar={<Avatar src={message?.author?.picture || DEFAULT_AVATAR} />}
        content={
          <div style={{ position: 'relative' }}>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{renderMessageContent(message.text, loggedInUserId, users, setUser)}</Text>

            {showMenuPopup && <MessageMenuPopup onReaction={handleReaction} />}
          </div>
        }
        datetime={
          <Tooltip title={moment(message.createdAt).format('MMMM Do YYYY, h:mm:ss a')}>
            <Text type="secondary" style={{ fontSize: 12 }}>
              {moment(message.createdAt).format('H:mm')} | {moment(message.createdAt).fromNow()}
            </Text>
          </Tooltip>
        }
      />
    </div>
  );
};

export default Message;

const getNameFromEmail = (email: string) => email.split('@')[0].charAt(0).toUpperCase() + email.split('@')[0].slice(1).toLowerCase();

const renderTextWithMentions = (
  text: string,
  loggedInUserId: number,
  users: UserBrief[],
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>,
) => {
  const mentionRegex = /(\|?@[^|]+\|?)/g;
  const parts: (string | JSX.Element)[] = [];
  let lastIndex = 0;

  text.replace(mentionRegex, (match, _, offset) => {
    parts.push(text.slice(lastIndex, offset));

    const userName = match.replace(/\||@/g, '').trim();

    const user = users.find(u => u.name === userName);

    if (user) {
      parts.push(<UserHoverCard key={offset} user={user} loggedInUserId={loggedInUserId || 0} setUser={setUser} />);
    } else {
      parts.push(`@${userName}`);
    }

    lastIndex = offset + match.length;
    return match;
  });

  parts.push(text.slice(lastIndex));

  return <>{parts}</>;
};

const renderMessageContent = (
  text: string,
  loggedInUserId: number,
  users: UserBrief[],
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>,
) => {
  const regex = new RegExp(`${CHAT_UPLOAD_BASE_URL}[^\\s]+`, 'g');

  const parts = text.split(regex);
  const matches = text.match(regex) || [];

  return (
    <div>
      {parts.map((part, index) => (
        <span key={index}>
          {renderTextWithMentions(part, loggedInUserId, users, setUser)}
          {matches[index] && (
            <Typography.Link href={matches[index]} target="_blank" rel="noopener noreferrer">
              {matches[index].split('/').pop()}
            </Typography.Link>
          )}
        </span>
      ))}
    </div>
  );
};
