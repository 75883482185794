import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'use-debounce';
import { Button, Divider, Empty, Spin } from 'antd';
import moment from 'moment';
import { DownCircleOutlined } from '@ant-design/icons';
import { fetchMessages, setIsAtBottomReducer } from '../../../store/reducers/messagesSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { ChatMessage } from '../../entities/chat';
import Message from './Message';
import NoMessages from '../../static/images/no_messages.svg';
import { CHAT_MESSAGE_HEIGHT, AMOUNT_OF_LOADED_MESSAGES } from '../../constants';
import { createActivity, fetchActivity } from '../../../store/reducers/activitySlice';
import { UserBrief } from '../../../dal';

import './Messages.css';

interface MessagesProps {
  loggedInUserId: number;
  users: UserBrief[];
  channelId?: string;
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>;
}

const Messages = ({ loggedInUserId, users, channelId, setUser }: MessagesProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const isFetching = useRef(false);

  const dispatch = useAppDispatch();
  const { activity } = useAppSelector(state => state.activityReducer);
  const { messages: actualMessages } = useAppSelector(state => state.messagesReducer);

  const [hasScrolledToFirstUnread, setHasScrolledToFirstUnread] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [hasMoreOld, setHasMoreOld] = useState(true);
  const [hasMoreNew, setHasMoreNew] = useState(true);
  const [initialScroll, setInitialScroll] = useState<number | undefined>(undefined);
  const [lastReadMessageId, setLastReadMessageId] = useState('');
  const [lastViewedMessage, setLastViewedMessage] = useState<ChatMessage | null>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const [debouncedLastReadMessageId] = useDebounce(lastViewedMessage?.id, 1000);

  useEffect(() => {
    if (!channelId) {
      setHasScrolledToFirstUnread(true);
    }
  }, [channelId]);

  useEffect(() => {
    const loadActivityAndMessages = async (channelId: string) => {
      if (activity) {
        if (!lastReadMessageId) {
          setLastReadMessageId(activity.lastReadMessageId);
        }

        return;
      }

      try {
        const { lastReadMessageId: fetchedLastReadMessageId } = await dispatch(fetchActivity({ channelId })).unwrap();
        if (fetchedLastReadMessageId) {
          setLastReadMessageId(fetchedLastReadMessageId);

          const fetchedMessages = await dispatch(fetchMessages({ channelId, lastReadMessageId: fetchedLastReadMessageId })).unwrap();

          if (fetchedMessages[fetchedMessages.length - 1].id === fetchedLastReadMessageId) {
            setHasMoreNew(false);
          }
        } else {
          const messages = await dispatch(fetchMessages({ channelId })).unwrap();

          if (messages.length < AMOUNT_OF_LOADED_MESSAGES) {
            setHasMoreOld(false);
          }

          if (messages.length) {
            await dispatch(
              createActivity({
                channelId,
                lastReadMessageId: messages[messages.length - 1].id,
              }),
            ).unwrap();

            setLastReadMessageId(messages[messages.length - 1].id);
          }
        }
      } catch (error) {
        console.error('Error fetching activity and messages:', error);
      }
    };

    if (channelId) {
      loadActivityAndMessages(channelId);
    }
  }, [activity, channelId, dispatch, lastReadMessageId]);

  const messages = useMemo(() => (channelId && actualMessages[channelId] ? actualMessages[channelId] : []), [actualMessages, channelId]);

  const lastReadMessage = useMemo(() => messages?.find(({ id }) => id === lastReadMessageId), [messages, lastReadMessageId]);
  const topMessageId = useMemo(() => messages?.[0]?.id, [messages]);
  const bottomMessageId = useMemo(() => messages?.[messages.length - 1]?.id, [messages]);

  useEffect(() => {
    if (activity && messages.length && !hasScrolledToFirstUnread && lastReadMessage) {
      const unreadIndex = messages.findIndex(m => m.createdAt > lastReadMessage.updatedAt);
      const scrollPosition = (unreadIndex !== -1 ? unreadIndex : messages.length) * CHAT_MESSAGE_HEIGHT - CHAT_MESSAGE_HEIGHT;

      setInitialScroll(scrollPosition);
      setHasScrolledToFirstUnread(true);
    }

    if (scrollRef.current && isAtBottom) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      setHasScrolledToFirstUnread(true);
    }
  }, [activity, hasScrolledToFirstUnread, isAtBottom, lastReadMessage, messages]);

  const handleScroll = () => {
    if (!scrollRef.current || isFetching.current) return;

    const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;
    const calculatedIndex = (scrollTop + clientHeight - CHAT_MESSAGE_HEIGHT * 1.2) / CHAT_MESSAGE_HEIGHT;
    const lastReadMessageIndex = Math.trunc(calculatedIndex);

    if (messages[lastReadMessageIndex]) {
      const lastReadMessage = messages[lastReadMessageIndex];

      if (!lastViewedMessage || (lastViewedMessage && lastReadMessage.createdAt > lastViewedMessage.createdAt)) {
        setLastViewedMessage(lastReadMessage);
      }
    }

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
    setIsAtBottom(isBottom);

    if (scrollTop <= 100 && hasMoreOld) {
      isFetching.current = true;

      loadOlderMessages();
    }

    setShowScrollButton(scrollTop + clientHeight < scrollHeight - 100);

    if (!channelId) {
      return;
    }

    dispatch(setIsAtBottomReducer({ channelId, isAtBottom: isBottom }));
  };

  const scrollToBottom = async () => {
    if (!scrollRef.current || !channelId) return;

    if (!hasMoreNew) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' });
      return;
    }

    try {
      const messages = await dispatch(fetchMessages({ channelId })).unwrap();

      if (messages.length) {
        if (!hasMoreOld && messages.length >= AMOUNT_OF_LOADED_MESSAGES) {
          setHasMoreOld(true);
        }

        await dispatch(
          createActivity({
            channelId,
            lastReadMessageId: messages[messages.length - 1].id,
          }),
        ).unwrap();

        setHasMoreNew(false);
        scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight });
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    if (channelId && debouncedLastReadMessageId) {
      dispatch(createActivity({ channelId, lastReadMessageId: debouncedLastReadMessageId })).unwrap();
    }
  }, [channelId, debouncedLastReadMessageId, dispatch]);

  const loadOlderMessages = useCallback(() => {
    if (channelId && topMessageId) {
      const prevScrollHeight = scrollRef.current?.scrollHeight || 0;

      if (initialScroll) {
        setInitialScroll(undefined);
      }

      dispatch(fetchMessages({ channelId, lastReadMessageId: topMessageId, direction: 'before' }))
        .unwrap()
        .then(data => {
          if (data.length < AMOUNT_OF_LOADED_MESSAGES) {
            setHasMoreOld(false);
          }

          if (scrollRef.current) {
            const newScrollHeight = scrollRef.current.scrollHeight;
            scrollRef.current.scrollTop = newScrollHeight - prevScrollHeight + CHAT_MESSAGE_HEIGHT;
          }
        })
        .finally(() => {
          isFetching.current = false;
        });
    }
  }, [channelId, dispatch, initialScroll, topMessageId]);

  const loadNewerMessages = useCallback(() => {
    if (!channelId || !bottomMessageId || !hasMoreNew || lastReadMessageId === messages[messages.length - 1].id) return;

    if (initialScroll) {
      setInitialScroll(undefined);
    }

    dispatch(fetchMessages({ channelId, lastReadMessageId: bottomMessageId, direction: 'after' }))
      .unwrap()
      .then(data => {
        if (data.length < AMOUNT_OF_LOADED_MESSAGES) {
          setHasMoreNew(false);
        }

        const newLastReadMessageId = messages[messages.length - 1].id;
        dispatch(createActivity({ channelId, lastReadMessageId: newLastReadMessageId })).unwrap();
      });
  }, [bottomMessageId, hasMoreNew, lastReadMessageId, messages, initialScroll, dispatch, channelId]);

  const renderDivider = useCallback(
    (message: ChatMessage, index: number) => {
      const prevMessage = messages?.length ? messages[index - 1] : null;
      const isNewDay = prevMessage ? new Date(message.createdAt).toDateString() !== new Date(prevMessage?.createdAt).toDateString() : false;
      const isNew = lastReadMessage ? message.createdAt > lastReadMessage?.createdAt : false;
      const isMessageFromMe = message.author?.id === loggedInUserId;
      const isFirstUnread =
        isNew && !isMessageFromMe && !messages?.slice(0, index).some(m => m.createdAt > (lastReadMessage ? lastReadMessage.createdAt : 0));

      return (
        (isNewDay || isFirstUnread) && (
          <Divider className={`chat-divider ${isFirstUnread ? 'new-divider' : ''}`}>
            {isNewDay && isFirstUnread ? (
              <span className="chat-divider-badge new-divider-badge">{formatMessageDate(new Date(message.createdAt))}</span>
            ) : isNewDay ? (
              <span className="chat-divider-badge">{formatMessageDate(new Date(message.createdAt))}</span>
            ) : (
              <span className="chat-divider-new">New</span>
            )}
          </Divider>
        )
      );
    },
    [lastReadMessage, loggedInUserId, messages],
  );

  if (!hasScrolledToFirstUnread) {
    return <Spin />;
  }

  return (
    <div ref={scrollRef} style={{ height: `calc(100vh - 305px)`, overflow: 'auto' }} id="scrollableDiv" onScroll={handleScroll}>
      <InfiniteScroll
        key={messages.length}
        dataLength={messages.length}
        next={loadNewerMessages}
        hasMore={hasMoreNew}
        scrollableTarget="scrollableDiv"
        // loader={<Spin />}
        loader={false}
        initialScrollY={initialScroll}
      >
        {messages.length && channelId ? (
          messages.map((message, index) => (
            <div key={message.id}>
              {renderDivider(message, index)}
              <Message message={message} channelId={channelId} loggedInUserId={loggedInUserId} users={users} setUser={setUser} />
            </div>
          ))
        ) : (
          <Empty image={NoMessages} description="No messages yet"></Empty>
        )}
      </InfiniteScroll>

      {showScrollButton && (
        <Button icon={<DownCircleOutlined style={{ fontSize: '30px' }} />} onClick={scrollToBottom} className="scroll-to-bottom-btn" />
      )}
    </div>
  );
};

export default Messages;

const formatMessageDate = (date: Date) => {
  const now = moment();
  const momentDate = moment(date);

  if (momentDate.isSame(now, 'day')) return 'Today';
  if (momentDate.isSame(now.clone().subtract(1, 'day'), 'day')) return 'Yesterday';

  return momentDate.format('dddd, MMMM Do');
};
