import { useState } from 'react';
import { Col, Row, Spin } from 'antd';
import Layout from '../../layouts/chat/Chat';
import Messages from '../../components/chat/Messages';
import Editor from '../../components/chat/Editor';
import { useProject, UserBrief, useSession, useUsersBrief } from '../../../dal';

import './index.scss';
import UserCard from '../../components/chat/UserCard';

const ChannelChat = ({ projectId }: { projectId: string }) => {
  const { session, isError, isLoading } = useSession();
  const { project, isLoading: projectLoading } = useProject(projectId);
  const { users, isLoading: usersLoading } = useUsersBrief({ activeOnly: true });
  const [user, setUser] = useState<UserBrief | null>(null);

  const { internalChatId: channelId, autoCode: projectCode } = project;

  if (isError && !isLoading) {
    return (
      <Layout>
        <Col flex="auto" className="h-full justify-center items-center">
          <span className="flex h-full justify-center items-center cursor-pointer !m-0 font-[Proxima Nova] font-[700] text-[#585858] text-[21px] !pl-4 text-ellipsis overflow-hidden whitespace-nowrap">
            Sorry, chat server is unavailable.
          </span>
        </Col>
      </Layout>
    );
  }

  if (!session || projectLoading || usersLoading) {
    return (
      <Layout>
        <Col flex="auto" className="h-full justify-center items-center">
          <div className="flex h-full justify-center items-center">
            <Spin />
          </div>
        </Col>
      </Layout>
    );
  }

  return (
    <Layout>
      <Row style={{ flexDirection: 'row', height: '100%' }}>
        <Col
          style={{
            transition: 'width 0.3s ease',
            width: user ? 'calc(100% - 300px)' : '100%',
          }}
        >
          <Messages loggedInUserId={session?.id} channelId={channelId} users={users} setUser={setUser} />
          <Editor channelId={channelId} projectId={projectId} projectCode={projectCode} users={users} />
        </Col>

        {user && (
          <Col
            style={{
              transition: 'transform 0.3s ease-in-out',
              transform: user ? 'translateX(0)' : 'translateX(100%)',
            }}
          >
            <UserCard onClose={() => setUser(null)} user={user} />
          </Col>
        )}
      </Row>
    </Layout>
  );
};

export default ChannelChat;
