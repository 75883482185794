import { Popover, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import EmojiPicker from 'emoji-picker-react';

interface MessageMenuPopupProps {
  onReaction: (
    emojiData:
      | {
          emoji: string;
        }
      | string,
  ) => void;
}

const MessageMenuPopup = ({ onReaction }: MessageMenuPopupProps) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 40,
        right: 50,
        background: 'white',
        padding: '8px',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        gap: '8px',
      }}
    >
      <Popover content={<EmojiPicker onEmojiClick={onReaction} />} trigger="click">
        <Button shape="circle" icon={<SmileOutlined />} size="small" />
      </Popover>
    </div>
  );
};

export default MessageMenuPopup;
