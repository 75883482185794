import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatMessage } from '../../chat/entities/chat';

import { axiosBackend } from '../../api/backend';
import { receiveEvent } from './pusherEventsSlice';

interface MessagesState {
  messages: Record<string, ChatMessage[]>;
  isAtBottom: Record<string, boolean>;
  isLoading: boolean;
  error: string | null;
}

const initialState: MessagesState = {
  messages: {},
  isAtBottom: {},
  isLoading: false,
  error: null,
};

export const fetchMessages = createAsyncThunk<ChatMessage[], { channelId: string; lastReadMessageId?: string; direction?: 'before' | 'after' }>(
  'messages/fetchMessages',
  async ({ channelId, lastReadMessageId, direction }, { rejectWithValue }) => {
    try {
      const queryParam = lastReadMessageId ? `?${direction || 'last_seen_id'}=${lastReadMessageId}` : '';
      const response = await axiosBackend.get(`/chat/${channelId}/messages${queryParam}`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to fetch messages');
    }
  },
);

export const createMessage = createAsyncThunk<
  ChatMessage,
  {
    channelId: string;
    text: string;
    replyToId?: string;
    attachedFiles?: {
      filterKey?: string;
      file: File;
    }[];
    channelName?: string;
  }
>('messages/createMessage', async (payload, { rejectWithValue }) => {
  const { channelId, text, replyToId, attachedFiles, channelName } = payload;

  try {
    const response = await axiosBackend.post(`/chat/${channelId}/messages`, {
      text,
      replyToId: replyToId || null,
      createChannel: channelName || null,
      files: attachedFiles?.map(data => `${data.file.name}&${data.file.type}&${data.file.size}`),
    });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to create message');
  }
});

export const updateMessageReaction = createAsyncThunk<
  ChatMessage,
  {
    messageId: string;
    channelId: string;
    emoji: string;
  }
>('messages/updateMessageReaction', async (payload, { rejectWithValue }) => {
  const { messageId, channelId, emoji } = payload;

  try {
    const response = await axiosBackend.patch(`/chat/${channelId}/messages/${messageId}/reaction`, {
      emoji,
    });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to update reaction to message');
  }
});

const handleNewMessage = (state: MessagesState, newMessage: ChatMessage, channelId: string) => {
  const channelMessages = state.messages[channelId] || [];

  const isDuplicate = channelMessages.some(msg => msg.id === newMessage.id);

  if (!isDuplicate) {
    state.messages[channelId] = [...channelMessages, newMessage];
  }
};

const handleUpdateMessage = (state: MessagesState, newMessage: ChatMessage, channelId: string) => {
  const channelMessages = state.messages[channelId] || [];

  const messageIndex = channelMessages.findIndex(msg => msg.id === newMessage.id);

  if (messageIndex !== -1) {
    state.messages[channelId][messageIndex] = newMessage;
  }
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setIsAtBottomReducer: (state, action: PayloadAction<{ channelId: string; isAtBottom: boolean }>) => {
      state.isAtBottom[action.payload.channelId] = action.payload.isAtBottom;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMessages.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        const messages = action.payload;

        if (messages.length === 0) {
          return;
        }

        const channelId = action.meta.arg.channelId;
        const existingMessages = state.messages[channelId] || [];

        if (!action.meta.arg.direction) {
          state.messages[channelId] = messages;
          return;
        }

        if (action.meta.arg.direction === 'before') {
          state.messages[channelId] = [...messages, ...existingMessages];
          return;
        }

        if (action.meta.arg.direction === 'after') {
          state.messages[channelId] = [...existingMessages, ...messages];
          return;
        }
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Error loading messages';
      })
      .addCase(createMessage.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        const message = action.payload;

        const { id: channelId } = message.channel;

        if (state.isAtBottom[channelId]) {
          handleNewMessage(state, message, channelId);
        }
      })
      .addCase(createMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to create message';
      })
      .addCase(updateMessageReaction.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateMessageReaction.fulfilled, (state, action) => {
        state.isLoading = false;
        const message = action.payload;

        const { id: messageId } = message;
        const { id: channelId } = message.channel;

        if (!state.messages[channelId]) return;

        state.messages[channelId] = state.messages[channelId].map(msg => (msg.id === messageId ? message : msg));
      })
      .addCase(updateMessageReaction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to create message';
      })
      .addCase(receiveEvent, (state, action) => {
        const { eventName, data } = action.payload;

        if (eventName === 'chat:new-message') {
          const { channelId, message } = data;

          if (channelId && !state.messages[channelId]) {
            state.messages[channelId] = [];
          }

          handleNewMessage(state, message, channelId);
        }

        if (eventName === 'chat:new-reaction') {
          const { channelId, message } = data;

          if (channelId && !state.messages[channelId]) {
            state.messages[channelId] = [];
          }

          handleUpdateMessage(state, message, channelId);
        }
      });
  },
});

export const { setIsAtBottomReducer } = messagesSlice.actions;

export default messagesSlice.reducer;
